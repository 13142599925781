<template>
  <b-modal id="hardwareModal" title="Hardware ändern" size="xl" @ok="ok" @hidden="reset" @show="onShow">
    <b-form @submit.stop.prevent="">
      <b-form-row>
        <b-col cols="3">
          <label>Bezeichnung:</label>
          <b-form-input v-model="form.displayname" placeholder="Bezeichnung..." trim/>
        </b-col>
        <b-col cols="3">
          <label>Hersteller:</label>
          <b-form-input v-model="form.factoryname" placeholder="Hersteller..." trim/>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="3">
          <label>Hintergrundfarbe:</label>
          <b-form-input v-model="form.color_background" type="color"/>
        </b-col>
        <b-col cols="3">
          <label>Textfarbe:</label>
          <b-form-select v-model="fontColorSelect.selected" :options="fontColorSelect.options"/>
        </b-col>
        <b-col cols="3">
          <label/>
          <div class="ml-2 mt-2">
            <h4><b-badge :style="badgeStyle">{{ form.displayname }}</b-badge></h4>
          </div>
        </b-col>
      </b-form-row>
    </b-form>
    <template #modal-footer="{ ok, cancel }">
      <b-button :variant="variantDanger" @click="cancel()">Abbrechen</b-button>
      <b-button :variant="variantSuccess" @click="ok">
        <b-spinner v-show="loadingOkButton" small/>
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {putRequest} from "@/modules/requests";
import {mapGetters} from "vuex";

export default {
  name: "HardwareModal",
  props: ['data'],
  data() {
    return {
      form: {
        id: null,
        displayname: '',
        factoryname: '',
        color_background: ''
      },
      fontColorSelect: {
        selected: null,
        options: [
          {value: '#ffffff', text: 'Weiß'},
          {value: '#000000', text: 'Schwarz'}
        ]
      },
      loadingOkButton: false
    }
  },
  methods: {
    reset() {
      this.form = {
        id: null,
        displayname: '',
        factoryname: '',
        color_background: ''
      }
      this.fontColorSelect.selected = null
    },
    refresh() {
      this.$emit('refresh');
    },
    async ok() {
      await this.updateRow()
      this.reset()
    },
    async onShow() {
      this.form.id = this.data.id
      this.form.displayname = this.data.displayname
      this.form.factoryname = this.data.factoryname
      this.form.color_background = this.data.color_background
      this.fontColorSelect.selected = this.data.color_font
    },
    async updateRow() {
      let data = {
        id: this.form.id,
        displayname: this.form.displayname,
        factoryname: this.form.factoryname,
        color_font: this.fontColorSelect.selected,
        color_background: this.form.color_background
      }

      await putRequest('hardware/fg/update', data, this, 'Der Eintrag wurde erfolgreich aktualisiert.', 'Der Eintrag konnte nicht aktualisiert werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('hardwareModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    }
  },
  computed: {
    badgeStyle() {
      return {
        'color': this.fontColorSelect.selected,
        'background-color': this.form.color_background
      }
    },
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
