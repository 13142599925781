<template>
  <div>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <SubHeader class="mb-2">
          <b-row class="mt-2 mb-1">
            <b-col cols="3">
              <b-form-input v-model="search" debounce="500" size="sm" placeholder="Suche nach Hardware..."/>
            </b-col>
          </b-row>
        </SubHeader>
        <div v-if="hardwareFiltered">
          <div v-for="hardwareConfig in hardwareFiltered" :key="hardwareConfig.id" class="mt-3">
            <HardwareConfiguration :hardwareConfig="hardwareConfig" @edit-hardware="edit"/>
          </div>
        </div>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <ScrollToTop/>
    <HardwareModal :data="data" @refresh="refresh"/>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions} from "vuex";
import {getRequest} from "@/modules/requests";
import SubHeader from "@/components/structure/SubHeader.vue";
import ScrollToTop from "@/components/utility/ScrollToTop.vue";
import HardwareModal from "@/components/fg-software/hardware/HardwareModal.vue";
import HardwareConfiguration from "@/components/fg-software/hardware/HardwareConfiguration.vue";

export default {
  name: "HardwareManage",
  components: {
    SubHeader,
    ScrollToTop,
    HardwareModal,
    HardwareConfiguration
  },
  data() {
    return {
      search: '',
      data: null,
      hardware: null
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    edit(data) {
      this.data = data;
      setTimeout(() => {
        this.$bvModal.show('hardwareModal');
      }, 100);
    },
    async getHardwareData() {
      await getRequest('/hardware/fg/all', null, this)
          .then((response) => {
            this.hardware = response.data
          })
    },
    async refresh() {
      this.setLoadingState(true)
      await this.getHardwareData()
      this.setLoadingState(false)
    },
  },
  computed: {
    hardwareFiltered() {
      return _.filter(this.hardware, (entry) => {
        return entry.displayname.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  created() {
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
</style>
