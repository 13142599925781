<template>
  <b-card body-class="p-0" header-tag="header" class="shadow-sm">
    <template #header>
      <div class="text-center noselect">
        {{ hardwareConfig.displayname }}
      </div>
    </template>
    <b-card-body class="pt-3 pb-2" style="font-size: 14px">
      <b-row>
        <b-col cols="4">
          <b-row class="mb-2">
            <b-col>
              <b-badge variant="primary">{{ hardwareConfig.count }}</b-badge>
            </b-col>
            <b-col cols="auto" class="ml-auto">
              <b-icon-pencil-square :id="'tt-editHardware-' + hardwareConfig.id" font-scale="1.2" class="clickable"
                                    @click="editHardware(hardwareConfig)"/>
              <b-tooltip :target="'tt-editHardware-' + hardwareConfig.id" triggers="hover">Bearbeiten</b-tooltip>
            </b-col>
          </b-row>
          <b-row v-for="(value, key) in configData" :key="key">
            <b-col cols="3" class="text-left">
              {{ key }}
            </b-col>
            <b-col cols="9" class="text-right">
              <span v-if="key === 'Darstellung'">
                <b-badge :style="badgeStyle">{{ hardwareConfig.displayname }}</b-badge>
              </span>
              <span v-else>
                <span v-if="value">{{ value }}</span>
                <span v-else>-</span>
              </span>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="8" class="ml-auto">
          <div v-if="hardwareConfig.hardware_variations.length > 0">
            <HardwareVariationTable :tableItems="hardwareConfig.hardware_variations"/>
          </div>
          <b-row v-else cols="1">
            <b-col>
              <h6 class="text-secondary text-center">Keine Variationen vorhanden</h6>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import HardwareVariationTable from "@/components/fg-software/hardware/HardwareVariationTable";

export default {
  name: "HardwareConfiguration",
  props: ['hardwareConfig'],
  components: {
    HardwareVariationTable
  },
  data() {
    return {
      expand: false
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    editHardware(data) {
      this.$emit('edit-hardware', data)
    }
  },
  computed: {
    badgeStyle() {
      return {
        'color': this.hardwareConfig.color_font,
        'background-color': this.hardwareConfig.color_background
      }
    },
    configData() {
      if(this.hardwareConfig) {
        return {
          'Displayname': this.hardwareConfig['displayname'],
          'Hersteller': this.hardwareConfig['factoryname'],
          'CPU': this.hardwareConfig['cpu'],
          'GPU': this.hardwareConfig['gpu_external'],
          'Darstellung': null
        }
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
