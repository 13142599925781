<template>
  <b-table :fields="table.fields" :items="tableItems" :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc"
           :class="tableClasses" thead-class="table-header text-white text-center"  tbody-tr-class="table-row" striped
           small caption-top>
    <template #custom-foot>
      <b-tr class="table-footer">
        <b-th v-for="(field, index) in table.fields" :key="index"/>
      </b-tr>
    </template>
  </b-table>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "HardwareVariationTable",
  props: ['tableItems'],
  data() {
    return {
      table: {
        sortBy: 'count',
        sortDesc: true,
        fields: [
          {key: 'id', label: 'ID', tdClass: 'text-center', sortable: true},
          {key: 'ram', label: 'RAM'},
          {key: 'ram_size', label: 'Größe (GB)', tdClass: 'text-center'},
          {key: 'disk', label: 'Festplatte'},
          {key: 'disk_size', label: 'Größe (GB)', tdClass: 'text-center'},
          {key: 'nic_lan', label: 'LAN'},
          {key: 'count', label: 'Anzahl', tdClass: 'text-center', sortable: true}
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    tableClasses() {
      if(this.userThemeLight) {
        return []
      }
      return ['text-white']
    }
  }
}
</script>

<style lang="scss">
@import '../../../assets/styles/Tables';
</style>
